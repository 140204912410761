import React, { useState } from "react";
import { navigate } from "gatsby-link";

import { isAuthorized } from "../services/auth";
import LoginForm from "./LoginForm";
import { app } from "../config";
import ModalPortal from "./ModalPortal";
import Veil from "./Veil";

const Authorized = ({ scope, children, validate, onSuccess }) => {
  if (scope.endsWith("/")) {
    scope = scope.substring(0, scope.length - 1);
  }
  const [userIsAuthorized, setUserIsAuthorized] = useState(isAuthorized(scope));
  const message = app.routes.find((r) => r.path === scope)?.authMessage;
  const handleSuccess = () => {
    setUserIsAuthorized(isAuthorized(scope));
    if (typeof onSuccess === "function") {
      onSuccess();
    }
  };
  const handleClose = () => {
    navigate(app.rootPath);
  };
  return (
    <div>
      {!userIsAuthorized && (
        <ModalPortal>
          <Veil />
          <LoginForm
            key="loginForm"
            show={!userIsAuthorized}
            scope={scope}
            onSuccess={handleSuccess}
            onClose={handleClose}
            validate={validate}
            message={message}
          />
        </ModalPortal>
      )}
      {!!userIsAuthorized && children}
    </div>
  );
};

export default Authorized;
